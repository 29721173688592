<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>New Work Order</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-combobox
                        v-model="workOrder.project"
                        :items="projects"
                        item-text="name"
                        hide-details
                        prepend-icon="mdi-account-hard-hat"
                        prefix="Project: "
                        :disabled="!!projectId"
                    >
                    </v-combobox>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-textarea
                        v-model="workOrder.notes"
                        prepend-icon="mdi-text"
                        prefix="Notes: "
                        hide-details
                        rows="2"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="create"
                rounded
                :loading="loading"
            >
                CREATE NEW ORDER
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'WorkOrderForm',
    props: {
        projectId: {
            type: String,
            deafult: () => '',
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        workOrder: {},
        projects: [],
    }),
    async mounted() {
        try {
            this.loading = true
            if (this.project && this.project.id) {
                this.projects = [this.project]
                this.workOrder.project = this.project
            } else {
                this.projects = await API.getLiteProjects({})
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async create() {
            try {
                this.loading = true
                await API.createWorkOrder({
                    projectId: this.workOrder.project.id,
                    notes: this.workOrder.notes,
                })
                this.$emit('addWorkOrder')
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
